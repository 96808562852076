@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  font-family: "Poppins";
  border: none;
  transition: all 0.3s ease;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: 0s;
}
body {
  background-color: #ffffff;
}

.card {
  border: none;
  box-shadow: 1px 1px 5px 0px black;
}

.shadow {
  box-shadow: 3px 2px 20px 0px black;
}

.container {
  padding: 0%;
  max-width: unset;
}

.nav-container {
  padding: 0;
  margin: 0;
}

.nav-bar {
  background-color: #261e4d;
  height: max-content;
  padding: 10px 0px 10px 0;
}

.social {
  width: fit-content;
  place-content: center;
  background-color: white;
  border-radius: 30px;
  margin-left: auto;
  margin-top: 5px;
  padding: 0 15px 0 15px;
  box-shadow: 3px 2px 20px 0px black;
}

.linkedin {
  color: #2867b2;
  padding: 0;
  margin: 0px 5px 0px 0px;
}

.github {
  color: #211f1f;
  padding: 0;
  margin: 0px 0px 0px 5px;
}

a {
  text-decoration: none;
  color: #1eff9d;
  padding: 0 10px 0 10px;
}

.nav-links {
  color: #1eff9d;
  font-size: 2em;
}

#body {
  color: #1f1143;
}

@media (max-width: 992px) {
  .logo {
    font-weight: bold;
    font-size: 1.5em;
    padding: 0;
    margin: 0 0 0 0;
  }
  .nav-links {
    margin-top: 5px;
    font-size: 1em;
  }
  .title {
    padding: 10px 0 0 95px;
  }

  #header p {
    margin: 0.5em 1em 1em -0.5em;
    padding: 2em 0px 1em 1em;
    font-weight: 700;
    font-size: 3.5em;
    line-height: 0.8em;
    letter-spacing: -0.05em;
  }
  /* Body Section Styles */

  #body .title {
    padding: 0;
    margin: 50px 0 0 0;
    font-size: 2em;
    text-align: center;
  }

  #body img {
    width: inherit;
    width: 90vw;
    position: absolute;
    margin: -10vw 0 10vw 0vw;
    z-index: -1;
  }

  #body .degree-cs {
    margin: 0;
    text-align: center;
    margin: 50px 0px 0 0;
  }

  #body .degree-cs h3 {
    padding: 0;
    margin: 0 0 0 0px;
  }

  #body .degree-ai {
    margin: 0;
    text-align: center;
    margin: 50px 0px 0 0;
  }
  #body .degree-ai h3 {
    margin: 0;
    padding: 0;
  }
  #body .degree-b-cs {
    margin: 3vw 50px 50px 40px;
    text-align: center;
    margin: 50px 0px 30px 0;
  }
  #body .degree-b-cs h3 {
    margin: 0 0 0 0px;
    padding: 0;
  }
  #slider .title {
    padding: 0;
    text-align: center;
    font-size: 2em;
  }
}
/* Slider setion styles */

@media (min-width: 992px) {
  .logo {
    font-weight: bold;
    font-size: 1.5em;
    padding: 0;
    margin: 0 0 0 0;
  }
  .nav-links {
    margin-top: 5px;
    font-size: 1em;
  }
  .title {
    padding: 10px 0 0 95px;
  }

  #header p {
    margin: 0.5em 1em 1em -0.5em;
    padding: 2em 0px 1em 1em;
    font-weight: 700;
    font-size: 5rem;
    line-height: 0.8em;
    letter-spacing: -0.05em;
  }

  /* Body Section Styles */

  #body .title {
    padding: 0;
    margin: 30px 0 20px 60px;
    font-size: 2em;
  }

  #body img {
    width: inherit;
    width: 80vw;
    position: absolute;
    margin: -20vw 0 10vw 10vw;
    z-index: -1;
  }

  #body .degree-cs {
    margin: 50px 20px 3vw 40px;
  }

  #body .degree-cs h3 {
    padding: 0;
    margin: 0 0 0 0px;
  }

  #body .degree-ai {
    margin: 3vw 0 4vw 60vw;
  }
  #body .degree-ai h3 {
    margin: 0;
    padding: 0;
  }
  #body .degree-b-cs {
    margin: 3vw 50px 50px 40px;
  }
  #body .degree-b-cs h3 {
    margin: 0 0 0 0px;
    padding: 0;
  }

  /* Slider setion styles */
  #slider .title {
    margin: 0 0 0 -30px;
    font-size: 2em;
  }
}

#slider .title {
  font-weight: 200;
}
a:hover {
  color: #1eff9d;
}

.header {
  width: auto;
  height: 30%;
  background-color: #1f1143;
  border-radius: 0 0 10px 10px;
  color: #1eff9d;
}

h3 {
  position: relative;
  margin: 1em 0.5em 1em 1.3em;
}

#education h4 {
  font-size: 1.7em;
  font-weight: 500;
}

#education h3 {
  font-size: 1em;
  font-weight: 100;
  padding: 0 0 0 80px;
}
:is(#education) .title {
  margin: 0;
  padding: 40px 0 40px 50px;
}
#body img {
  max-width: max-content;
}

#triangle-bottomright {
  width: 0;
  height: 0;
  border-bottom: 230px solid #d2d7f5;
  border-left: 200px solid transparent;
  position: absolute;
  margin: -200px 0 50px 50px;
  z-index: -1;
  rotate: 10px 10px 0;
}

/* Slider Styles */

.slick-dots {
  position: absolute;
  left: 0;
  padding-bottom: 20px;
}

.slider-section {
  background-color: #e9ecf8;
  border-radius: 10px 10px 10px 10px;
  padding: 40px 0 40px 0;
}

.slider-container {
  padding: 20px 20px 20px 20px;
  margin: 10px;
}

.slider-item {
  color: #1f1f1f;
  padding: 0px 0 0 0;
  margin: 15px;
  text-align: center;
}

.slider-item :is(h4, h3, .title) {
  color: #1f1143;
  padding: 0 0 0 0;
  margin: 0;
  font-weight: 300;
}

.slider-item :is(h4) {
  font-size: 1.2em;
}

.slider-item :is(p) {
  padding: 10px 0 0 0;
  margin: 0;
  font-size: 1.2em;
  font-weight: 300;
}

#slider img {
  width: auto;
  max-width: 80%;
  align-self: center;
}
/* Arrow styling */

.arrow {
  position: absolute;
  color: #1f1f1f;
}
.arrow:hover {
  color: #2867b2;
}
.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}
/* Skills section styles */
:is(#skills, footer) .title {
  padding: 0;
  font-size: 2em;
  font-weight: 200;
}
.skills-section {
  padding: 40px 0 40px 0;
}
.skills-section h4 {
  margin: 0;
  padding: 0 0 0 0px;
  font-weight: 200;
}

.skill-item {
  margin: 0 0 0 0;
  padding: 0 0 10px 0;
}

#skills .col-md-3 {
  padding: 30px 0 0 0;
}
#skills img {
  width: 80px;
}

#java :is(img, svg) {
  padding: 0px 0 0 0;
  margin: -25px 0 0px 0;
}

#java h4 {
  margin-top: 0;
  padding-top: 0;
}

.fa-react {
  color: skyblue;
}

/* Footer styles */
.footer {
  background-color: #383a3b;
  color: #fff1d4;
  transition: all 0.3s ease;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: 0s;
}

.footer button {
  margin: 20px;
}

.footer .title {
  padding: 0 0 50px 0;
  font-size: 1.5em;
  font-weight: 500;
  text-align: center;
}

label {
  color: rgba(255, 241, 212, 0.87);
}

.form-control {
  padding-top: 10px;
}
.form-control:focus {
  background-color: transparent;
  color: rgba(255, 241, 212, 0.87);
}
.form-control {
  color: rgba(255, 241, 212, 0.87);
  background-color: rgba(0, 0, 0, 0.582);
  border-color: none;
  border-radius: 9px;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus,
.form-control:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #333538 inset !important;
  -webkit-text-fill-color: rgba(255, 241, 212, 0.87);
}
/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #333538 inset !important;
  color: rgba(255, 241, 212, 0.87);
} */
@media (max-width: 768px) {
  .connectwithme {
    padding: 100px 100px 80px 80px;
  }
}

@media (min-width: 769px) {
  .connectwithme {
    padding: 100px 30vw 80px 30vw;
  }
}

.error {
  color: rgb(247, 72, 72);
  margin: 0 !important;
  background-color: none;
}

.form-style {
  text-align: left;
}

#floatingTextArea {
  height: 150px;
}

.btn-primary {
  background-color: rgba(0, 0, 0, 0.582);
  color: rgba(255, 241, 212, 0.87);
  margin: 0;
}

.btn-primary:hover {
  background-color: #333538;
}
/* Fields BG rgba(0,0,0,.25) */
